import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [2,4];

export const dictionary = {
		"/(frontend)": [~5,[2]],
		"/(frontend)/account": [~24,[2]],
		"/admin": [26,[4]],
		"/admin/customers": [~33,[4]],
		"/admin/customers/[customer]": [34,[4]],
		"/admin/orders": [~31,[4]],
		"/admin/orders/[order]": [~32,[4]],
		"/admin/products": [~30,[4]],
		"/admin/promotions": [~27,[4]],
		"/admin/promotions/edit": [28,[4]],
		"/admin/promotions/edit/[id]": [~29,[4]],
		"/(frontend)/blog": [~15,[2]],
		"/(frontend)/blog/[slug]": [~16,[2]],
		"/(frontend)/brands": [~10,[2]],
		"/(frontend)/brands/[brand]": [~11,[2]],
		"/(frontend)/cart": [~17,[2]],
		"/(frontend)/category/[category]": [~12,[2]],
		"/(frontend)/category/[category]/[subcategory]": [~13,[2]],
		"/(frontend)/(checkout)/checkout": [~18,[2,3]],
		"/(frontend)/(checkout)/order-summary": [~21,[2,3]],
		"/(frontend)/(checkout)/order-thank-you": [~20,[2,3]],
		"/(frontend)/(checkout)/orders": [~19,[2,3]],
		"/(frontend)/(pharmacy)/pharmacy-results": [~8,[2]],
		"/(frontend)/sales-and-clearance": [~14,[2]],
		"/(frontend)/searchresult": [~22,[2]],
		"/(frontend)/symptoms": [~6,[2]],
		"/(frontend)/symptoms/[symptom]": [~7,[2]],
		"/(api)/test2/template": [25],
		"/(frontend)/[pages]": [~9,[2]],
		"/(frontend)/[categories]/[product]": [~23,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';