import type { HandleClientError } from '@sveltejs/kit';
// import { H } from 'highlight.run';
// import { PUBLIC_HIGHLIGHT } from '$env/static/public';
// import { dev } from '$app/environment';

// const highlightData = H.init(PUBLIC_HIGHLIGHT, {
// 	environment: dev ? 'development' : 'production',
// 	version: 'commit:abcdefg12345',
// 	tracingOrigins: true,
// 	manualStart: false,
// 	inlineImages: false,
//
// 	networkRecording: {
// 		enabled: true,
// 		recordHeadersAndBody: true
// 		/*urlBlocklist: [
// 				// insert full or partial urls that you don't want to record here
// 				// Out of the box, Highlight will not record these URLs (they can be safely removed):
// 				"https://www.googleapis.com/identitytoolkit",
// 				"https://securetoken.googleapis.com",
// 		],*/
// 	}
// });

//Needed to set the highlight session id
// try {
//   window.fetch('/highlight',{
//     method:"OPTIONS",
//     body:JSON.stringify({
//       highlightData
//     }),
//     headers:{
//       'Content-Type':'application/json'
//     }
//   });
//   
// } catch (error) {
//     console.warn(error);
// }


//Add errors?
export const handleError = (({ error, event }) => {
	//Potential to save errors to Sentry
	const errorId = crypto.randomUUID();
	console.log(error, event);

	return {
		message: 'error',
		errorId
	};
}) satisfies HandleClientError;
